import React, { useState } from 'react';

const FAQ = () => {
    const collapseData = [

        {
            question: "What is TF Masters?",
            answer: "TFMasters is the revolutionary App that enriches the learning experience by combining educational content with interactive features, making it enjoyable and effortless to grasp even the most complex trade finance concepts."
            // answer : "Master trade finance with TF Masters: expert content, interactive challenges, real-world insights, and Easy learning—all in one app!"
        },
        {
            question: "How often do you update your library?",
            answer: "We update on daily basis. Our Registered users will get notified with our daily digest emails for every new post."
        },
        // {
        //     question: "When is the Pro Version expected?",
        //     answer: "We are diligently working to bring you the Pro Version as soon as possible. While we don’t have an exact date yet, rest assured that our team is striving to deliver it to you at the earliest."
        // },
        // {
        //     question: "I have completed the registration process. How long does it take for verification?",
        //     answer: "Our expert panel typically completes verifications within 24 to 48 hours. During this time, we ensure that all provided details are accurately verified to maintain the integrity of our community."
        // },
        {
            question: "I am new to Trade Finance. Will it be useful for me?",
            answer: "Absolutely! This app’s unique ability to present Trade Finance concepts in an enjoyable, stress-free manner helps users retain information more effectively without the fatigue or boredom often associated with traditional study methods."
        },
        {
            question: "I am already an expert in Trade Finance. What will be new for me?",
            answer: "Even seasoned professionals can hit a learning plateau—TFMasters helps you refresh and stay sharp and enthusiastic with easy-to-access, up-to-date content and challenges."
        },
        // {
        //     question: "I am a certified professional. Do I still need verification?",
        //     answer: "While certification demonstrates your proficiency in trade finance, verification on our platform ensures the accuracy and authenticity of your profile information. It helps build trust within our community and enhances your credibility as a member. Therefore, we recommend completing the verification process regardless of your certification status."
        // },

    ]

    const [collapsed, setCollapsed] = useState((Array(collapseData.length).fill(true)));

    const toggleCollapse = (index) => {

        const newCollapsedState = [...collapsed];
        newCollapsedState[index] = !newCollapsedState[index];
        setCollapsed(newCollapsedState);
    };


    return (
        <div className="container" id="faq">
            <div className="heading">Frequently asked questions</div>
            <div className="description">
                <span class="description_sub">Find quick answers to common queries in our FAQ section,</span> designed to streamline your learning experience.
            </div>
            {collapseData.map((item, index) => {
                return <div className='collapseContainer' key={index}>
                    <button className="collapsible" onClick={() => toggleCollapse(index)}>
                        <div>  {item.question}​{<span className={`icon ${collapsed[index] ? 'plus' : 'minus'}`}>{collapsed ? '+' : '-'}</span>}</div>
                        <div className={`content ${collapsed[index] ? 'collapsed' : ''}`}>
                            <p>{item?.answer}​</p>
                        </div>
                    </button>

                </div>
            })}
        </div>
    )
}

export default FAQ;