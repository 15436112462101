import { useState, useEffect } from 'react'
import TFMLogo from '../../assets/images/logo.svg';
import '../../App.scss'
import Images from '../../assets/images'

function Redirect() {
    const [appNotInstalled, setAppNotInstalled] = useState(false);
    const { AppStore, PlayStore } = Images
  const params = new URLSearchParams(window.location.search);
  const postId = params.get('p');
  const type = params.get('t');

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAndroid = /android/i.test(navigator.userAgent);

// 
  const APP_STORE_URL = 'https://apps.apple.com/in/app/tf-masters/id6474083932';
  const PLAY_STORE_URL =
    'https://play.google.com/store/apps/details?id=com.venzo.tfm';

  useEffect(() => {
    handleRedirect();

    const timer = setTimeout(() => {
      setAppNotInstalled(true);
      if (isIOS) {
        window.location.href = APP_STORE_URL;
      } else if (isAndroid) {
        window.location.href = PLAY_STORE_URL;
      }
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  const handleRedirect = () => {
    if (postId) {
      if (type) {
        try {
          window.location.href = `tfm://detailedpost/${postId}/${type}`;
        } catch (error) {
          if (isIOS) {
            window.location.href = APP_STORE_URL;
          } else if (isAndroid) {
            window.location.href = PLAY_STORE_URL;
          }
        }
      } else {
        try {
          window.location.href = `tfm://challenges/${postId}`;
        } catch (error) {
          if (isIOS) {
            window.location.href = APP_STORE_URL;
          } else if (isAndroid) {
            window.location.href = PLAY_STORE_URL;
          }
        }
      }
    }
  };

  const handleStoreRedirect = (url) => {
    console.log(url);
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <div className='redirect-container'>
      <img src={TFMLogo} alt="TFM Logo" style={styles.logo} />
      <h2 style={styles.title}>
        {appNotInstalled ? "Download TFM App" : "Opening TFM App..."}
      </h2>
      <p style={styles.message}>
        {appNotInstalled
          ? "Looks like you don't have the TFM app installed. We're redirecting you to download it."
          : "If the app doesn't open automatically, click the button below."}
      </p>
      {!appNotInstalled && (
        <button style={styles.button} onClick={handleRedirect}>
          Open TFM App
        </button>
      )}
      {appNotInstalled && (
        <div>
          <p style={{ color: '#888'}}>Choose your platform:</p>
          {isIOS && (
            <button
              style={styles.storeButton}
              onClick={() => handleStoreRedirect(APP_STORE_URL)}
            >
              <img src={AppStore} alt="appstore" /> App Store
            </button>
          )}
          {isAndroid && (
            <button
              style={styles.storeButton}
              onClick={() => handleStoreRedirect(PLAY_STORE_URL)}
            >
              <img src={PlayStore} alt="playstore" /> Play Store
            </button>
          )}
          {!isIOS && !isAndroid && (
            <>
            {APP_STORE_URL && (
                    <button
                    style={styles.storeButton}
                    onClick={() => handleStoreRedirect(APP_STORE_URL)}
                    >
                   <img src={AppStore} alt="appstore" /> App Store
                    </button>
              )}
              {PLAY_STORE_URL && (
                <button
                  style={styles.storeButton}
                  onClick={() => handleStoreRedirect(PLAY_STORE_URL)}
                >
                  <img src={PlayStore} alt="playstore" /> Play Store
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: '20px',
    textAlign: 'center',
  },
  logo: {
    maxWidth: '200px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#888',
    fontFamily: 'Urbanist',
  },
  message: {
    fontSize: '16px',
    marginBottom: '20px',
    color: '#888',
    fontFamily: 'Urbanist',
  },
  button: {
    padding: '12px 24px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontFamily: 'Urbanist',
  },
  storeButton: {
    display: 'flex',
    margin: '10px',
    padding: '12px 24px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'black',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontFamily: 'Urbanist',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
};

export default Redirect;