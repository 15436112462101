import Images from "../../assets/images";
const OurApp = () => {
    const { OurApp, AppStore, PlayStore } = Images
    return (
        <div className="ourAppContainer">
            <div className="contentContainer">
            {/* <a href="https://play.google.com/store/apps/details?id=com.venzo.tfm&pli=1" target="_blank" rel="noopener noreferrer">
                       
                        <button className="downloadButton">Download</button>
                        </a> */}
                <div className="heading" style={{ paddingTop: "1%" }}>Our app is available for
                    Android & iOS</div>
                <div className="description">
                    Access our app seamlessly on both Android and iOS platforms for on-the-go learning convenience​.
                </div>

                <div className='buttonGroup'>
                <a href="https://apps.apple.com/in/app/tf-masters/id6474083932" target="_blank" rel="noopener noreferrer">
                    <button><img src={AppStore} alt="appstore" /> App Store</button>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.venzo.tfm&pli=1" target="_blank" rel="noopener noreferrer">
                <button>
                    <img src={PlayStore} alt="playstore" />
                    Play Store
                </button>
                </a>
                </div>

            </div>
            <div className="imageContainer">
                <img src={OurApp} alt="ourApp" height="382px" width="340.3px" />
            </div>
        </div>
    )
}

export default OurApp;