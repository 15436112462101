import React, { useState } from 'react';
// import RegisterModal from "../modal/registerModal";
import Images from '../../assets/images';
import "./layout.scss"
const Header = () => {

  const { Logo } = Images
  const [isModalOpen, setIsModalOpen] = useState(false);


  return (
    <div className='headerComponent'>
      <img src={Logo} alt="TFMasters" />

      {/* <button className='registerButton' onClick={() => setIsModalOpen(true)}>Join The Waitlist</button> */}
      {/* {isModalOpen && <RegisterModal onClose={() => setIsModalOpen(false)} />} */}

    </div>
  )
}

export default Header;