import Images from "../../assets/images";
const Learning = () => {
    const {LearningWeb, Window_design} = Images;
    return (
        <div className="container" style={{ position: "relative", overflow:"hidden"}}>
        {/* <div className="heading">Learning on the go</div>
       <img src={LearningWeb} alt="LearningWeb" className="learningWeb" width={"auto"} height={"auto"}/> */}
       <div className="gridContainer">
        <div className="ContentSection">
       <div className="Head">
       Prepare To Unlock The Secrets Of TF Masters! 
       </div>
       {/* <span>Coming Soon</span> */}
<div className="desc">Get ready for an immersive learning experience with our upcoming courses, connect with industry peers on our networking platform, explore career opportunities on our job board, and engage in insightful discussions on our forum.</div>
        </div>
        <div className="imageSection">
        <img src={LearningWeb} alt="LearningWeb" className="learningWeb" width={"auto"} height={"auto"}/> 

        </div>
        <div>

        </div>
       </div>
       <img class="window_design" src={Window_design} alt="windowEffect" width={"auto"} height={"auto"} />
       <img class="window_designr" src={Window_design} alt="windowEffect" width={"auto"} height={"auto"} />
        </div>
    )
    }
    
    export default Learning;