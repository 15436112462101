import React from 'react';
import Images from '../../assets/images';

const FutureGoals = () => {
    const { Course, Trade, Forum, Job } = Images
    return (
        <div className="container" id="comingsoon">
            {/* <div className="heading">Coming Soon​</div> */}
            <div className="heading">Our Exciting New Features!​</div>
            <div className="description">
                Several new features will soon be added to the app, designed to enhance Trade Finance learning, streamline access to Trade Finance resources, and facilitate connections with industry professionals, all while providing a more seamless and comprehensive user experience.
            </div>
            <div className="goalsContainer">
                <div className="stepContainer1">
                    <div className='cardHeader'>
                        <div className='redCircle'></div>
                        <div className='yellowCircle'></div>
                        <div className='greenCircle'></div>
                    </div>
                    <div className='cardDesc'>
                        <div className="textHeading"><img src={Course} alt="User" width={"auto"} height={"auto"} /> Courses</div>
                        <div className='textDesc'>
                        Embark on your journey to mastery with our expertly crafted courses. From beginner basics to advanced strategies, dive deep into the world of trade finance with engaging content.
                        </div>
                    </div>

                </div>
                <div className="stepContainer2">
                    <div className='cardHeader'>
                        <div className='redCircle'></div>
                        <div className='yellowCircle'></div>
                        <div className='greenCircle'></div>
                    </div>
                    <div className='cardDesc'>
                        <div className="textHeading"><img src={Trade} alt="User" width={"auto"} height={"auto"} />Network</div>
                        <div className='textDesc'>
                        Join our bustling network of trade finance enthusiasts and professionals. Share insights, and stay ahead of the curve together.
                        </div>
                    </div>

                </div>
                <div className="stepContainer3">
                    <div className='cardHeader'>
                        <div className='redCircle'></div>
                        <div className='yellowCircle'></div>
                        <div className='greenCircle'></div>
                    </div>
                    <div className='cardDesc'>
                        <div className="textHeading"><img src={Job} alt="User" width={"auto"} height={"auto"} />Job Board</div>
                        <div className='textDesc'>
                        Discover exciting opportunities in the field of trade finance, ranging from entry-level positions to senior roles. Take the next step towards your professional aspirations today.
                        </div>
                    </div>

                </div>
                <div className="stepContainer4">
                    <div className='cardHeader'>
                        <div className='redCircle'></div>
                        <div className='yellowCircle'></div>
                        <div className='greenCircle'></div>
                    </div>
                    <div className='cardDesc'>
                        <div className="textHeading"><img src={Forum} alt="User" width={"auto"} height={"auto"} /> Forum</div>
                        <div className='textDesc'>
                        Our forum is your go-to destination for lively conversations, Q&A sessions, and knowledge exchange. Pose questions, share experiences, and connect with fellow learners and industry experts.
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )

}

export default FutureGoals;